var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-card", attrs: { id: "chat-app2" } }, [
    _c(
      "div",
      { staticClass: "chat-app" },
      [
        _c("Conversation", {
          attrs: { contact: _vm.selectedContact, messages: _vm.messages },
          on: { newMessage: _vm.saveNewMessage },
        }),
        _c("ContactsList", {
          attrs: { contacts: _vm.contacts },
          on: { selected: _vm.startConversationWith },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }