<template>
      <div id="chat-app2" class="vx-card">
        <div class="chat-app">
          <Conversation :contact="selectedContact" :messages="messages" @newMessage="saveNewMessage"></Conversation>
          <ContactsList :contacts="contacts" @selected="startConversationWith"></ContactsList>
        </div>
      </div>
</template>

<script>
import Conversation from './Conversation'
import ContactsList from './ContactsList'
export default {
  data () {
    return {
      selectedContact: null,
      messages: [],
      contacts: []

    }
  },
  watch: {

  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    startConversationWith (contact) {
      this.updateUnreadCount(contact, true)
      this.$http.get(`${process.env.VUE_APP_API_URL}/chat/getMessages/${contact.id}`)
        .then((response) => {
          this.messages = response.data
          this.selectedContact = contact
        })
        .catch((error) => { console.log(error) })
    },
    saveNewMessage (message) {
      this.messages.push(message)
    },
    handleIncoming (message) {
      if (this.selectedContact && message.from_user_id === this.selectedContact.id) {
        this.saveNewMessage(message)
      }
      this.updateUnreadCount(message.from_user, false)
    },
    updateUnreadCount (contact, reset) {
      this.contacts = this.contacts.map(single => {
        if (single.id !== contact.id) {
          return single
        }
        if (reset) {
          single.unread = 0
        } else {
          single.unread += 1
        }
        return single
      })
    }
  },
  mounted () {
    this.$http.get(`${process.env.VUE_APP_API_URL}/users/getAll/notMe`)
      .then((response) => {
        this.contacts = response.data
      })
      .catch((error)   => { console.log(error) })

    /**
     * AQUI ESCUTO OS EVENTOS DE CHAT DO LARAVEL
     * PARA TESTE USAR A URL http://dev.sigcfc.com.br/getAll
    */
    window.Echo.channel('public').listen('.chat', e => {
      this.handleIncoming(e.message)
    })

    // AO ENTRAR NO CHAT ZERA O CONTADOR DO AVISO NAS BARRAS DO TOPO E MENU PERFIL
    this.$store.commit('UPDATE_USER_INFO', { unreadChat: 0 })

  },

  components: {
    Conversation,
    ContactsList
  }
}

</script>

<style lang="scss" scoped>
 .chat-app {
   display: flex;
 }
</style>
