<template>
    <div class="contacts-list">
      <ul>
        <li v-for="contact in sortedContacts" :key="contact.id" @click="selectContact(contact)" :class="{ 'selected': contact === selected }">
          <vs-avatar :alt="contact.name" class="avatar m-1 border-white border-2 border-solid shadow-md" :src="contact.photo ? contact.photo.url : null" size="35px" />
          <div class="contact">
            <p class="name">{{ contact.name }}</p>
            <p class="email">{{ contact.email }}</p>
            <span class="unread" v-if="contact.unread">{{ contact.unread }}</span>
          </div>
        </li>
      </ul>
    </div>
</template>

<script>

import _ from 'lodash'

export default {
  props: {
    contacts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sortedContacts () {
      return _.sortBy(this.contacts, [
        (contact) => {
          if (contact === this.selected) {
            return Infinity
          }
          return contact.unread
        }
      ]).reverse()
    }
  },
  data () {
    return {
      selected: this.contacts.length ? this.contacts[0] : null
    }
  },
  methods: {
    selectContact (contact) {
      this.selected = contact
      this.$emit('selected', contact)
    }
  }
}

</script>

<style lang="scss" scoped>
 .contacts-list {
   flex: 2;
   max-height: 600px;
   min-height: 600px;
   overflow: scroll;
   border-left: 1px solid #a6a6a6;
   ul {
     list-style-type: none;
     padding-left: 0;
     li {
       display: flex;
       align-items: center;
       padding: 2px;
       border-bottom: 1px solid #aaaaaa;
       height: 80px;
       position: relative;
       cursor: pointer;

       &.selected {
         background: #dfdfdf;
       }

       span.unread {
         background: #82e0a8;
         color: #fff;
         position: absolute;
         right: 11px;
         top: 20px;
         display: flex;
         font-weight: 700;
         min-width: 20px;
         justify-content: center;
         align-items: center;
         line-height: 20px;
         font-size: 12px;
         padding: 0 4px;
         border-radius: 3px;
       }
     }
     .contact {
       flex: 3;
       font-size: 11px;
       overflow: hidden;
       display: flex;
       flex-direction: column;
       justify-content: center;

       p {
         margin: 0;
         &.name {
           font-weight: bold;
         }
       }
     }
   }
 }
</style>
