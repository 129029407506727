var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contacts-list" }, [
    _c(
      "ul",
      _vm._l(_vm.sortedContacts, function (contact) {
        return _c(
          "li",
          {
            key: contact.id,
            class: { selected: contact === _vm.selected },
            on: {
              click: function ($event) {
                return _vm.selectContact(contact)
              },
            },
          },
          [
            _c("vs-avatar", {
              staticClass:
                "avatar m-1 border-white border-2 border-solid shadow-md",
              attrs: {
                alt: contact.name,
                src: contact.photo ? contact.photo.url : null,
                size: "35px",
              },
            }),
            _c("div", { staticClass: "contact" }, [
              _c("p", { staticClass: "name" }, [_vm._v(_vm._s(contact.name))]),
              _c("p", { staticClass: "email" }, [
                _vm._v(_vm._s(contact.email)),
              ]),
              contact.unread
                ? _c("span", { staticClass: "unread" }, [
                    _vm._v(_vm._s(contact.unread)),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }