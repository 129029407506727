var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "feed", staticClass: "feed" }, [
    _vm.contact
      ? _c(
          "ul",
          _vm._l(_vm.messages, function (message, i) {
            return _c(
              "li",
              {
                key: i,
                class: `message${
                  message.to_user_id === _vm.contact.id ? " sent" : " received"
                }`,
              },
              [
                _c("div", { staticClass: "text" }, [
                  _c("small", [_vm._v(_vm._s(message.created_at) + "h")]),
                  _c("br"),
                  _vm._v(" " + _vm._s(message.message) + "\n      "),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }