var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "composer" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.message,
          expression: "message",
        },
      ],
      attrs: { placeholder: "Mensagem..." },
      domProps: { value: _vm.message },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.send.apply(null, arguments)
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.message = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }