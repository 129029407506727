<template>
  <div class="feed" ref="feed">
    <ul v-if="contact">
      <li v-for="(message, i) in messages" :key="i" :class="`message${message.to_user_id === contact.id ? ' sent' : ' received'}`">
        <div class="text">
          <small>{{ message.created_at }}h</small> <br> {{ message.message }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object
    },
    messages: {
      type: Array,
      required: true
    }
  },
  watch: {
    contact () {
      this.scrollToBottom()
    },
    messages () {
      this.scrollToBottom()
    }
  },
  methods: {
    scrollToBottom () {
      setTimeout(() => {
        this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight - this.$refs.feed.clientHeight
      }, 50)
    }
  }
}
</script>

<style lang="scss" scoped>
.feed {
  background: #f0f0f0;
  height: 100%;
  max-height: 470px;
  overflow: scroll;
  ul {
    padding: 5px;
    li {
      &.message {
        margin: 10px 0px;
        width: 100%;
        .text {
          max-width: 200px;
          border-radius: 5px;
          padding: 12px;
          display: inline-block;
        }

        &.received {
          text-align: right;
          .text {
            background: #b2b2b2;
          }
        }
        &.sent {
          text-align: left;
          .text {
            background: #81c4b2;
          }
        }
      }
    }
  }
}
</style>
