<template>
    <div class="conversation">
      <h1>{{ contact ? contact.name : 'Selecione um contato.'}}</h1>
      <MessageFeed :contact="contact" :messages="messages"></MessageFeed>
      <MessageComposer @send="sendMessage"></MessageComposer>
    </div>
</template>

<script>
import MessageFeed from './MessageFeed'
import MessageComposer from './MessageComposer'

export default {
  props: {
    contact: {
      type: Object,
      default: null
    },
    messages: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  },
  methods: {
    sendMessage (text) {
      if (!this.contact) {
        return
      }
      this.$http.post(`${process.env.VUE_APP_API_URL}/chat/post`, {
        contact_id: this.contact.id,
        text
      })
        .then((response) => {
          this.$emit('newMessage', response.data)
        })
        .catch((error)   => { console.log(error) })
    }
  },
  components: {
    MessageFeed,
    MessageComposer
  }
}

</script>

<style lang="scss" scoped>
 .conversation {
   flex: 5;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   h1 {
     font-size: 20px;
     padding: 10px;
     margin: 0;
     border-bottom: 1px dashed lightgray;
   }
 }
</style>
