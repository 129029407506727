var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conversation" },
    [
      _c("h1", [
        _vm._v(
          _vm._s(_vm.contact ? _vm.contact.name : "Selecione um contato.")
        ),
      ]),
      _c("MessageFeed", {
        attrs: { contact: _vm.contact, messages: _vm.messages },
      }),
      _c("MessageComposer", { on: { send: _vm.sendMessage } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }